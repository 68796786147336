import { Image, ProviderImage } from '@zupr/types/fo'
import {
    Format,
    Transformation,
    imageProviderHashToUrl,
} from '@zupr/utils/image'
import classnames from 'classnames'
import React, { useMemo } from 'react'

import '../../../scss/react/components/figure.scss'

import { ProductImage } from '@zupr/types/bo'
import Picture from '../../../svg/picture.svg'

const urlCreator = global.URL || global.webkitURL

type ImageValue = string | Blob

export const useImage = (value?: ImageValue): string | undefined => {
    const uri = useMemo(() => {
        if (typeof value === 'string') return value
        if (!value) return
        if (value instanceof Blob) return urlCreator.createObjectURL(value)
        return value
    }, [value])
    return uri
}

interface ImageProps {
    url: string
    policy?: 'lazy' | 'eager'
    placeholder?: React.ReactNode
    className?: string
    cover?: boolean
    isLoading?: boolean
    alt: string
    children?: React.ReactNode
}

export const LoadingImage = ({
    placeholder,
    children,
    url,
    className,
    cover,
    isLoading,
    alt,
    policy,
}: ImageProps) => {
    const uri = useImage(url)
    return (
        <figure
            className={classnames(className, {
                cover,
                'figure-loading': isLoading,
            })}
        >
            {!!uri && <img src={url} alt={alt} loading={policy} />}
            {!url && placeholder}
            {!url && !placeholder && !isLoading && <Picture />}
            {children}
        </figure>
    )
}

LoadingImage.defaultProps = {
    alt: 'loading',
}

export const EagerImage = (props: ImageProps) => (
    <LoadingImage {...props} policy="eager" />
)
export const LazyImage = (props: ImageProps) => (
    <LoadingImage {...props} policy="lazy" />
)

interface FigureProps {
    url?: ImageValue
    placeholder?: React.ReactNode
    className?: string
    cover?: boolean
    isLoading?: boolean
    children?: React.ReactNode
}

const Figure = ({
    placeholder,
    children,
    url,
    className,
    cover,
    isLoading,
}: FigureProps) => {
    const uri = useImage(url)
    return (
        <figure
            className={classnames(className, {
                cover,
                'figure-loading': isLoading,
            })}
            style={{
                backgroundImage: (uri && `url(${uri})`) || undefined,
            }}
        >
            {!url && placeholder}
            {!url && !placeholder && !isLoading && <Picture />}
            {children}
        </figure>
    )
}

interface ProviderImageProps {
    image: ProviderImage | Image | ProductImage
    alt: string
    format?: Format
    policy?: 'lazy' | 'eager'
    transformation: Transformation
}

export const ProviderFigure = ({
    image,
    transformation,
    format,
    alt,
}: ProviderImageProps) => {
    const url = useMemo(() => {
        return imageProviderHashToUrl(image, format, transformation)
    }, [format, image, transformation])

    return <figure>{url && <img src={url} alt={alt} />}</figure>
}

ProviderFigure.defaultProps = {
    format: 'webp',
    policy: 'lazy',
}

export default Figure
